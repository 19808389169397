import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

const VideoHeader = ({ title }) => (
    <header className="post-title-header article-header tag-video">
        <div className="container text-center">
            <span className="tag-list"><Link to="/">Back</Link>Video Learning Series</span>
            <h1 className="content-title fs-2">{title}</h1>
        </div>
    </header>
)

VideoHeader.propTypes = {
    title: PropTypes.string,
}

export default VideoHeader