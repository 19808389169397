import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'

import { Layout } from '../components/common'
import { MetaData } from '../components/common/meta'
import VideoHeader from '../components/post/VideoHeader'
import SimilarPosts from '../components/post/SimilarPosts'
import GeneralPostHeader from '../components/post/GeneralPostHeader'
import CustomerStoryPost from '../components/post/CustomerStoryPost'
import ProductUpdatePost from '../components/post/ProductUpdatePost'

const getFirstTag = data => data && data.ghostPost && data.ghostPost.tags && data.ghostPost.tags.length !== 0 && data.ghostPost.tags[0]

/**
* Single post view (/:slug)
*
* This file renders a single post and loads all the content.
*
*/
const Post = ({ data, location }) => {
    const post                      = data.ghostPost;
    const firstTag                  = getFirstTag(data);
    const firstTagSlug              = firstTag ? firstTag.slug : null;
    const allPosts                  = data.allGhostPost.edges;
    const currentPostTagsSlugs      = post.tags.map(tag => tag.slug);
    const baseClasses               = `post-title-header article-header`;
    const fallbackPostClasses       = baseClasses;
    const roadmapsPostClasses       = `${baseClasses} tag-roadmaps`;
    const feedbackPostClasses       = `${baseClasses} tag-feedback`;
    const featuredPostClasses       = `${baseClasses} tag-featured`;
    const prioritizationPostClasses = `${baseClasses} tag-prioritization`;
    const customerStoryPostClasses  = `${baseClasses} tag-customer-story`;
    const productUpdatesPostClasses = `tag-product-updates`;
    const featuredPost              = post.featured;
    const productUpdatePost         = currentPostTagsSlugs.includes(`product-updates`);
    const customerStoryPost         = currentPostTagsSlugs.includes(`hash-customer-story`);
    
    return (
        <>
            <MetaData
                data={data}
                location={location}
                type="article"
            />
            <Helmet>
                <style type="text/css">{`${post.codeinjection_styles}`}</style>
            </Helmet>
            <Layout>
                {productUpdatePost &&
                    <ProductUpdatePost
                        post={post}
                        title={post.title}
                        featureImage={post.feature_image}
                        headerClasses={productUpdatesPostClasses}
                    />
                }
                {firstTagSlug === null && !featuredPost && !productUpdatePost &&
                    <GeneralPostHeader
                        post={post}
                        title={post.title}
                        featureImage={post.feature_image}
                        headerClasses={fallbackPostClasses}
                    />
                }
                {firstTagSlug === `hash-customer-story` && !featuredPost && !productUpdatePost &&
                    <CustomerStoryPost
                        post={post}
                        title={post.title}
                        featureImage={post.feature_image}
                        headerClasses={customerStoryPostClasses}
                    />
                }
                {firstTagSlug === `hash-video` && !featuredPost && !productUpdatePost &&
                    <VideoHeader
                        title={post.title}
                    />
                }
                {firstTagSlug === `roadmaps` && !featuredPost && !productUpdatePost &&
                    <GeneralPostHeader
                        post={post}
                        title={post.title}
                        featureImage={post.feature_image}
                        headerClasses={roadmapsPostClasses}
                    />
                }
                {firstTagSlug === `feedback` && !featuredPost && !productUpdatePost &&
                    <GeneralPostHeader
                        post={post}
                        title={post.title}
                        featureImage={post.feature_image}
                        headerClasses={feedbackPostClasses}
                    />
                }
                {featuredPost && !productUpdatePost &&
                    <GeneralPostHeader
                        post={post}
                        title={post.title}
                        featureImage={post.feature_image}
                        headerClasses={featuredPostClasses}
                    />
                }
                {firstTagSlug === `prioritization` && !featuredPost && !productUpdatePost &&
                    <GeneralPostHeader
                        post={post}
                        title={post.title}
                        featureImage={post.feature_image}
                        headerClasses={prioritizationPostClasses}
                    />
                }
                {!productUpdatePost && !customerStoryPost &&
                    <article className="content">
                        <section className="post-full-content">
                            <hr></hr>

                            {/* The main post content */}
                            <section
                                className="content-body load-external-scripts"
                                dangerouslySetInnerHTML={{ __html: post.html }}
                            />
                        </section>
                        <SimilarPosts
                            currentPostId={post.id}
                            currentPostTagSlugs={currentPostTagsSlugs}
                            allPosts={allPosts}
                        />
                    </article>
                }
            </Layout>
        </>
    )
}

Post.propTypes = {
    data: PropTypes.shape({
        allGhostPost: PropTypes.shape({
            edges: PropTypes.object,
            nodes: PropTypes.shape({
                tags: PropTypes.arrayOf(
                    PropTypes.shape({
                        slug: PropTypes.string,
                        name: PropTypes.string,
                    })
                ),
            }),
            title: PropTypes.string,
            feature_image: PropTypes.string,
            tags: PropTypes.arrayOf(
                PropTypes.shape({
                    slug: PropTypes.string,
                    name: PropTypes.string,
                })
            ),
        }),
        ghostPost: PropTypes.shape({
            codeinjection_styles: PropTypes.object,
            html: PropTypes.string.isRequired,
            feature_image: PropTypes.string,
            featured: PropTypes.bool.isRequired,
            title: PropTypes.string.isRequired,
            id: PropTypes.string,
            tags: PropTypes.arrayOf(
                PropTypes.shape({
                    slug: PropTypes.string,
                    name: PropTypes.string,
                })
            ),
        }).isRequired,
    }).isRequired,
    pageContext: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
}

export default Post

export const postQuery = graphql`
    query($slug: String!) {
        ghostPost(slug: { eq: $slug }) {
            ...GhostPostFields
        }
        allGhostPost {
            edges {
                node {
                    ...GhostPostFields
                }
            }
        }
    }
`
