import React from 'react'
import PropTypes from 'prop-types'
import SimilarPostCard from './SimilarPostCard'

const getSimilarPostSpecs = ({ currentPostId, currentPostTagSlugs, allPosts }) => {
    const postsWithSomeSharedTags = allPosts.filter((post) => {
        const postTagSlugs      = post.node.tags.map(tag => tag.slug);
        const isAProductUpdate  = postTagSlugs.includes('product-updates');
        const someTagsAreShared = postTagSlugs.some(
            postTagSlug => currentPostTagSlugs.some(
                currentPostTagSlug => postTagSlug === currentPostTagSlug
            )
        )
        return (someTagsAreShared && !isAProductUpdate) ? someTagsAreShared : null;
    })

    const currentPostIndex     = postsWithSomeSharedTags.findIndex(post => post.node.id === currentPostId);
    const numberOfSimilarPosts = postsWithSomeSharedTags.length - 1;
    
    const nextPost = currentPostIndex < numberOfSimilarPosts ? postsWithSomeSharedTags[currentPostIndex + 1].node
        : currentPostIndex === numberOfSimilarPosts ? postsWithSomeSharedTags[0].node
            : null

    const prevPost = currentPostIndex !== 0 ? postsWithSomeSharedTags[currentPostIndex - 1].node
        : currentPostIndex === 0 ? postsWithSomeSharedTags[numberOfSimilarPosts].node
            : null

    const similarPostSpecs = [
        [prevPost],
        [nextPost],
    ]
    
    return similarPostSpecs
}

const SimilarPosts = ({ currentPostId, currentPostTagSlugs, allPosts }) => {
    const similarPostSpecs = getSimilarPostSpecs({ currentPostId, currentPostTagSlugs, allPosts });
    
    return (
        <section className="similar-posts">
            <div className="container-sm">
                <h3 className="text-center">You might also like</h3>
                <div className="similar-post-list flex">
                    {similarPostSpecs.map(([similarPost], i) => (
                        similarPost ? <SimilarPostCard similarPost={similarPost} key={i} /> : null
                    ))}
                </div>
            </div>
        </section>
    )
}

SimilarPosts.propTypes = {
    currentPostId: PropTypes.any,
    currentPostTagSlugs: PropTypes.array,
    allPosts: PropTypes.array,
}

export default SimilarPosts