import React from 'react'
import PropTypes from 'prop-types'

const CustomerStoryPost = ({ post, title, featureImage, headerClasses }) => (
    <div>
        <header className={headerClasses}>
            <div className="container flex align-center">
                <div className="post-title">
                    <h1 className="content-title fs-1">{title}</h1>
                    <p className="fs-4">{post.excerpt}</p>
                    <a href="https://roadmunk.com/customers" className="customers-back-link">Back to Customer Stories</a>
                </div>
                <figure className="post-feature-image">
                    <img src={featureImage} alt={title} />
                </figure>
            </div>
        </header>
        <main>
            <article className="content customer-story-content">
                <section className="post-full-content">
                    <section
                        className="content-body load-external-scripts"
                        dangerouslySetInnerHTML={{ __html: post.html }}
                    />
                </section>
            </article>
        </main>
    </div>
)

CustomerStoryPost.propTypes = {
    post: PropTypes.object,
    title: PropTypes.string,
    featureImage: PropTypes.string,
    headerClasses: PropTypes.string,
}

export default CustomerStoryPost