import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { Tags } from '@tryghost/helpers-gatsby'

const GeneralPostHeader = ({ post, title, featureImage, headerClasses }) => (
    <header className={headerClasses}>
        <div className="container flex align-center">
            <div className="post-title">
                <span className="tag-list">
                    <Link to="/">Back</Link>
                    {post.tags.length !== 0 && <Tags post={post} visibility="public" autolink={false} />}
                </span>
                <h1 className="content-title fs-2">{title}</h1>
            </div>
            <figure className="post-feature-image">
                <img src={featureImage} alt={title} />
            </figure>
        </div>
    </header>
)

GeneralPostHeader.propTypes = {
    post: PropTypes.object,
    title: PropTypes.string,
    featureImage: PropTypes.string,
    headerClasses: PropTypes.string,
}

export default GeneralPostHeader