import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import Tags from '../common/Tags'
import { MdKeyboardArrowLeft } from 'react-icons/md'

const ProductUpdatePost = ({ post, title, featureImage, headerClasses }) => {
    const options               = { year : 'numeric', month : 'short', day : 'numeric' };
    const publishedDate         = post.published_at;
    const formattedPubishedDate = new Date(publishedDate).toLocaleString('en-US', options);
    
    return (
        <div className={headerClasses}>
            <div className="container back-link-container">
                <Link to="/product-updates">
                    <MdKeyboardArrowLeft className="react-arrow-left" />Back to Product Updates
                </Link>
            </div>
            <main>
                <header>
                    <div className="flex">
                        <div className="post-title">
                            <span className="post-card-tags tag-list">
                                <span>
                                    {
                                        post.tags.length && 
                                        <Tags post={post} />
                                    }
                                </span>
                                <span className="post-date">{formattedPubishedDate}</span>
                            </span>
                            <h1 className="content-title fs-2">{title}</h1>
                        </div>
                        {
                            featureImage &&
                            <figure className="post-feature-image">
                                <img src={featureImage} alt={title} />
                            </figure>
                        }
                    </div>
                </header>
                <article className="content">
                    <section className="post-full-content">
                        <hr></hr>
    
                        {/* The main post content */}
                        <section
                            className="content-body load-external-scripts"
                            dangerouslySetInnerHTML={{ __html: post.html }}
                        />
                    </section>
                </article>
            </main>
        </div>
    )
}

ProductUpdatePost.propTypes = {
    post: PropTypes.object,
    title: PropTypes.string,
    featureImage: PropTypes.string,
    headerClasses: PropTypes.string,
}

export default ProductUpdatePost
