import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { Tags } from '@tryghost/helpers-gatsby'

const SimilarPostCard = ({ similarPost }) => (
    <Link to={`/` + similarPost.slug} className="post-card tdn">
        <header className="post-card-header">
            {similarPost.feature_image &&
                <div
                    className="post-card-image"
                    style={{ backgroundImage: `url(${similarPost.feature_image})` }}
                    key={similarPost.id}>
                </div>
            }
        </header>
        <section className="post-card-excerpt">
            {similarPost.tags && 
                <div className="post-card-tags"> 
                    <Tags
                        post={similarPost}
                        visibility="public"
                        autolink={false}
                        key={similarPost.id} />
                </div>
            }
            <h3 className="post-card-title fs-4">{similarPost.title}</h3>
        </section>
    </Link>
)

SimilarPostCard.propTypes = {
    similarPost: PropTypes.object,
}
export default SimilarPostCard